<template>
  <v-row>
    <v-col
      cols="12"
    >
      <v-card>
        <v-card-title>
          {{ $t('messages.quiz') }}
        </v-card-title>
        <div class="table-toolbar">
          <remove
            v-if="selected.length > 0 && $can('quiz.delete')"
            @click="remove"
          />
          <add
            v-if="$can('quiz.create')"
            :to="{ name: 'dashboard.quiz-create' }"
          />
        </div>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="quizes.data"
          :server-items-length="quizes.total"
          :options.sync="pagination"
          :items-per-page="parseInt(quizes.per_page)"
          :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true, showCurrentPage: true, disableItemsPerPage: false }"
          :loading="loading"
          :show-select="!!$can('quiz.delete')"
          must-sort
          class="elevation-1"
          @update:options="getQuizes"
        >
          <template #[`item.title`]="{ item }">
            <router-link
              :to="{ name: 'dashboard.question', params: { quiz_id: item.id } }"
            >
              {{ item.title }}
            </router-link>
          </template>
          <template #[`item.status`]="{ item }">
            <v-chip
              :color="$getColor(item.status)"
              dark
            >
              {{ item.status === true ? 'active' : 'inactive' }}
            </v-chip>
          </template>
          <template #[`item.created_at`]="{ item }">
            {{ item.created_at | moment('YYYY-MM-DD HH:mm') }}
          </template>
          <template #[`item.updated_at`]="{ item }">
            {{ item.updated_at | moment('YYYY-MM-DD HH:mm') }}
          </template>
          <template
            v-if="$can('quiz.update')"
            #[`item.action`]="{ item }"
          >
            <DataTableAction
              :text="$t('action.edit')"
              icon="mdi-pencil"
              @click="$router.push({ name: 'dashboard.quiz-update', params: { id: item.id }})"
            />
            <CopyModal
              :quiz-id="item.id"
              @click="getQuizes"
            />
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// todo in the future separate permission for edit and duplicate
import pagination from '@/mixins/pagination'
import DataTableAction from '@/components/table-actions/VDataTableAction'
import CopyModal from '@/components/quiz/CopyModal'
import { add, remove } from '@/components/actions'
import authHeader from '@/utils'

export default {
  name: 'Quiz',
  components: {
    add, remove, DataTableAction, CopyModal
  },
  mixins: [pagination],
  data () {
    return {
      selected: [],
      loading: true,
      headers: [
        { text: '#', value: 'id' },
        { text: this.$t('messages.title'), value: 'title' },
        { text: this.$t('messages.status'), value: 'status' },
        { text: this.$t('messages.amount_questions'), value: 'questions_count' },
        { text: this.$t('messages.created_at'), value: 'created_at' },
        { text: this.$t('messages.updated_at'), value: 'updated_at' },
        this.$can('quiz.update') ? { text: this.$t('messages.actions'), value: 'action', sortable: false, width: '15px', align: 'right' } : {}
      ],
      quizes: {
        data: []
      }
    }
  },
  methods: {
    getQuizes () {
      this.$http
        .get('/quizes' + this.datatableOptions, { headers: authHeader() })
        .catch(error => this.displayError(error))
        .then(({ data }) => {
          this.loading = false
          this.quizes = data
        })
    },
    remove () {
      this.$http
        .delete('/quizes', { data: { id: this.selected.map(val => val.id) }, headers: authHeader() })
        .catch(error => this.displayError(error))
        .then(() => {
          // remove ids from current elements
          this.quizes.data = this.quizes.data.filter((el) => !this.selected.map(val => val.id).includes(el.id))
          this.selected = []
          this.$store.commit('setNotification', { color: 'error', message: this.$t('action.delete_success') })
        })
    }
  }
}
</script>
