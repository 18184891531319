<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="400"
  >
    <template #activator="{ on, attrs }">
      <v-icon
        small
        class="mr-1"
        v-bind="attrs"
        v-on="on"
      >
        mdi-content-copy
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="subtitle-1">
        Are you sure you want to duplicate this quiz and all of it's questions?
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="green darken-1"
          text
          @click.stop="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click.prevent="duplicateQuiz(quizId)"
        >
          Duplicate
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import authHeader from '@/utils'

export default {
  name: 'CopyModal',
  props: {
    quizId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    duplicateQuiz (QuizID) {
      this.$http
        .post('/quizes/duplicate/' + QuizID, {}, { headers: authHeader() })
        .catch(error => this.displayError(error))
        .then(({ data }) => {
          console.log('Duplicate response:' + data)
          this.dialog = false
          this.$emit('click')
        })
    }
  }
}
</script>
