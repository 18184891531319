var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('messages.quiz'))+" ")]),_c('div',{staticClass:"table-toolbar"},[(_vm.selected.length > 0 && _vm.$can('quiz.delete'))?_c('remove',{on:{"click":_vm.remove}}):_vm._e(),(_vm.$can('quiz.create'))?_c('add',{attrs:{"to":{ name: 'dashboard.quiz-create' }}}):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.quizes.data,"server-items-length":_vm.quizes.total,"options":_vm.pagination,"items-per-page":parseInt(_vm.quizes.per_page),"footer-props":{ itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true, showCurrentPage: true, disableItemsPerPage: false },"loading":_vm.loading,"show-select":!!_vm.$can('quiz.delete'),"must-sort":""},on:{"update:options":[function($event){_vm.pagination=$event},_vm.getQuizes]},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'dashboard.question', params: { quiz_id: item.id } }}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.$getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.status === true ? 'active' : 'inactive')+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,'YYYY-MM-DD HH:mm'))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updated_at,'YYYY-MM-DD HH:mm'))+" ")]}},(_vm.$can('quiz.update'))?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('DataTableAction',{attrs:{"text":_vm.$t('action.edit'),"icon":"mdi-pencil"},on:{"click":function($event){return _vm.$router.push({ name: 'dashboard.quiz-update', params: { id: item.id }})}}}),_c('CopyModal',{attrs:{"quiz-id":item.id},on:{"click":_vm.getQuizes}})]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }